import styled from 'styled-components';
import { display, width } from 'styled-utils';
import { GatsbyImage } from 'components/Common/GatsbyImage';

export const Image = styled(GatsbyImage)``;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const GridItem = styled.div`
  ${width({ width: ['50%', '50%', '25%'] })}
`;

export const SocialGridStyle = styled.div`
  ${GridItem} {
    &:nth-of-type(7),
    &:nth-of-type(8) {
      ${display({ display: ['none', 'none', 'block'] })};
    }
  }
`;
