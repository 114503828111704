import React from 'react';
import { useState, useRef, useCallback, useEffect } from 'react';
import { StickyElementStyle } from './StickyElement.styles';

function isFrameElementVisible(element: HTMLElement) {
  const frame = window.parent.document.getElementById(
    window?.frameElement?.id || ''
  );
  if (!frame) {
    return true;
  }

  const currentScroll = frame.scrollTop;
  const screenHeight = frame.clientHeight;
  const visibleArea = currentScroll + screenHeight;
  return (
    element.getBoundingClientRect().y > 0 &&
    element.getBoundingClientRect().y < visibleArea
  );
}
interface StickyElementProps {
  desktopEnabled?: boolean;
  position?: 'top' | 'bottom' | 'topDesktop';
  backgroundColor?: string;
  className?: string;
}

export const StickyElement: React.FC<StickyElementProps> = ({
  desktopEnabled,
  position,
  backgroundColor,
  children,
  className = 'StickyElement',
}) => {
  const [showCta, setShowCta] = useState(false);
  const desktopDisplay = desktopEnabled ? 'block' : 'none';

  const anchorEl = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!anchorEl?.current) {
      return;
    }

    const inIframe = window.parent.location.href !== window.location.href;
    const elem: HTMLDivElement = anchorEl.current;

    if (inIframe) {
      setShowCta(!isFrameElementVisible(anchorEl.current));
    } else {
      setShowCta(elem.getBoundingClientRect().y < 0);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <>
      <div ref={anchorEl} />
      {showCta && (
        <StickyElementStyle
          display={['block', desktopDisplay]}
          className={`stky ${className}`}
          position={position}
          backgroundColor={backgroundColor}
        >
          {children}
        </StickyElementStyle>
      )}
    </>
  );
};
