import React from 'react';
import { renderBlockWithId } from 'helpers/block-with-id';
import { BlockType } from 'types';

import {
  LadderHomePageQuery,
  SanityCollectionSliderBlockFragment,
  SanityHeroSlider,
  SanityLadderProductSliderFragment,
  SanitySimpleMediaWithTextBlockFragment,
  SanitySellPageMediaWithText,
  SanityImageBlock,
  SanitySellPageSpacer,
  SanityBorderContentFragment,
  SanityLadderHomeSliderFragment,
  SanityRichTextWithBackgroundFragment,
  SanityImageGridFragment,
  SanitySellPageStickyCta,
  SanitySellPageStickyCtaShowHide,
  SanitySellPageStickyHeader,
} from 'graphql-types';

import { SimpleMediaWithText } from 'components/SimpleMediaWithText';
import { SanityMediaWithText } from 'components/MediaWithText/SanityMediaWithText';
import { PDPPageContainer } from 'components/Pdp';
import { SanityHeroCarousel } from 'components/HeroCarousel/SanityHeroCarousel';
import { ProductSliderSanity } from 'components/Product/Slider/ProductSlider.sanity';
import { ImageBlockSanity } from 'components/ImageBlock/ImageBlock.sanity';
import { SocialImageGrid } from 'components/ImageGrid/ImageGrid';
import { Spacer } from 'components/Spacer/Spacer';
import { SanityCollectionSlider } from 'components/CollectionSlider/SanityCollectionSlider';
import { BorderContent } from 'components/BorderContent/BorderContent';
import { RichTextWithBackground } from 'components/RichTextWithBackground/RichTextWithBackground';
import { LadderHomeSliderSanity } from 'components/LadderHomeSlider/LadderHomeSlider.sanity';
import { LadderBlock } from 'templates/ladder/Ladder.styles';
import { SanityStickyCta } from 'components/StickyCta/SanityStickyCta';
import { StickyCtaShowHide } from 'components/StickyCta/StickyCtaShowHide';
import { SanityStickyHeader } from 'components/StickyHeader/SanityStickyHeader';

type BlocksArrayType = NonNullable<LadderHomePageQuery['page']>['blocks'];

export const renderHomePageBlock = (block: BlockType<BlocksArrayType>) =>
  renderBlockWithId(block, getBlock(block));

function getBlock(block: BlockType<BlocksArrayType>) {
  const type = block.__typename;
  const id = block._key;

  switch (type) {
    case 'SanityHeroSlider':
      return <SanityHeroCarousel key={id} fields={block as SanityHeroSlider} />;
    case 'SanityCollectionSliderBlock':
      return (
        <SanityCollectionSlider
          key={id}
          {...(block as SanityCollectionSliderBlockFragment)}
        />
      );
    case 'SanitySimpleMediaWithTextBlock':
      return (
        <PDPPageContainer>
          <SimpleMediaWithText
            fields={block as SanitySimpleMediaWithTextBlockFragment}
          />
        </PDPPageContainer>
      );
    case 'SanitySellPageMediaWithText':
      return (
        <SanityMediaWithText
          key={id}
          fields={block as SanitySellPageMediaWithText}
        />
      );
    case 'SanityLadderProductSlider':
      return (
        <ProductSliderSanity
          key={id}
          fields={block as SanityLadderProductSliderFragment}
        />
      );
    case 'SanityImageBlock':
      return <ImageBlockSanity key={id} fields={block as SanityImageBlock} />;
    case 'SanityImageGrid':
      return (
        <LadderBlock key={id}>
          <SocialImageGrid fields={block as SanityImageGridFragment} />
        </LadderBlock>
      );
    case 'SanitySellPageSpacer':
      return (
        <Spacer
          key={id}
          backgroundColor={
            (block as SanitySellPageSpacer)?.backgroundColor?.hex
          }
          margin={(block as SanitySellPageSpacer)?.margin}
          marginMobile={(block as SanitySellPageSpacer)?.marginMobile}
        />
      );
    case 'SanityBorderContent':
      return <BorderContent data={block as SanityBorderContentFragment} />;
    case 'SanityRichTextWithBackground':
      return (
        <RichTextWithBackground
          data={block as SanityRichTextWithBackgroundFragment}
        />
      );
    case 'SanityLadderHomeSlider':
      return (
        <LadderHomeSliderSanity
          fields={block as SanityLadderHomeSliderFragment}
        />
      );
    case 'SanitySellPageStickyCta':
      return (
        <SanityStickyCta key={id} fields={block as SanitySellPageStickyCta} />
      );
    case 'SanitySellPageStickyCtaShowHide':
      return (
        <StickyCtaShowHide
          key={id}
          enableHide={(block as SanitySellPageStickyCtaShowHide)?.enableHide}
        />
      );
    case 'SanitySellPageStickyHeader':
      return (
        <SanityStickyHeader
          key={id}
          fields={block as SanitySellPageStickyHeader}
        />
      );
    default:
      return `Unrecognized block type: ${type}`;
  }
}
