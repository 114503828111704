import React from 'react';
import { graphql } from 'gatsby';
import { SeoMetadata } from 'components/SeoMetadata/SeoMetadata';
import { LadderHomePageQuery } from 'graphql-types';
import { renderHomePageBlock } from './HomePage.blocks';
import { useLogPageViewed } from 'helpers/Amplitude';
import { useTagStandardPageView } from 'helpers/Tealium';
import { useGTMTagStandardPageView } from 'helpers/GTM';
import { LadderApp } from 'templates/ladder/LadderApp';
import { ImageLoadingWrapper } from 'components/Common/GatsbyImage';

export const HomePageTemplate: React.FC<{
  data: LadderHomePageQuery;
}> = ({ data }) => {
  const { blocks, seoMetadata } = data.page || {};

  useLogPageViewed();
  useTagStandardPageView('page_view');
  useGTMTagStandardPageView('page_view');

  return (
    <LadderApp>
      {seoMetadata && <SeoMetadata metaData={seoMetadata} slug="" />}
      {blocks
        ?.map((block) => block && renderHomePageBlock(block))
        .map((block, index) => (
          <ImageLoadingWrapper key={index} eager={index < 2} children={block} />
        ))}
    </LadderApp>
  );
};

export default HomePageTemplate;

export const query = graphql`
  query LadderHomePage {
    page: sanityLadderHomePage {
      seoMetadata {
        ...SeoMetadata
      }
      blocks {
        ...SanityHeroSlider
        ...SanityCollectionSliderBlock
        ...SanityLadderProductSlider
        ...SanitySimpleMediaWithTextBlock
        ...SanityImageGrid
        ...SanityImageBlock
        ...SanitySquareImageSlider
        ...SanitySellPageSpacer
        ...SanityBorderContent
        ...SanityLadderHomeSlider
        ... on SanitySellPageMediaWithText {
          ...SanitySellPageMediaWithText
        }
        ...SanityRichTextWithBackground
        ...SanitySellPageStickyCta
        ...SanitySellPageStickyHeader
        ...SanitySellPageStickyCtaShowHide
      }
    }
  }
`;
