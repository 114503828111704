import React, { FC } from 'react';
import { LadderHomeSlider } from './LadderHomeSlider';
import { LadderHomeSlide } from './LadderHomeSlider.model';
import { SanityImage, SanityLadderHomeSliderFragment } from 'graphql-types';
import { getSimplifiedImageData } from 'helpers/SanityMapper';

interface Props {
  fields: SanityLadderHomeSliderFragment;
}

export const LadderHomeSliderSanity: FC<Props> = ({ fields }) => (
  <LadderHomeSlider
    title={fields.title || ''}
    subtitle={fields.subtitleText}
    cta={fields.cta}
    bgColor={fields.backgroundColor?.hex}
    slides={
      fields.slides?.map(
        (slide): LadderHomeSlide => ({
          name: slide?.name || '',
          subname: slide?.subname,
          text: slide?._rawText,
          image: {
            image: getSimplifiedImageData(slide?.image?.image as SanityImage),
            alt: slide?.image?.alt,
          },
        })
      ) || []
    }
  />
);
