import React from 'react';
import { Grid, GridItem, Image, SocialGridStyle } from './ImageGrid.styles';
import { SanityImage, SanityImageGridFragment } from 'graphql-types';
import { getSimplifiedImageData } from 'helpers/SanityMapper';

type ImageGridType = {
  fields: SanityImageGridFragment;
};

export const ImageGrid: React.FC<ImageGridType> = ({ fields }) => {
  return (
    <Grid>
      {fields?.images?.map((image, idx) => {
        const itemImage = getSimplifiedImageData(image?.image as SanityImage);
        return (
          itemImage && (
            <GridItem key={idx}>
              <Image image={itemImage} alt={image?.alt || ''} />
            </GridItem>
          )
        );
      })}
    </Grid>
  );
};

/**
 * Special case for our generic Image grid component. It limits the amount
 * of images it can handle.
 */
export const SocialImageGrid: React.FC<ImageGridType> = ({ fields }) => {
  return (
    <SocialGridStyle>
      <ImageGrid
        fields={{
          ...fields,
          images: fields?.images?.slice?.(0, 8) || [],
        }}
      />
    </SocialGridStyle>
  );
};
