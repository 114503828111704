import { smallStyle, xSmallStyle } from 'components/Common/Text';
import styled from 'styled-components';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import ArrowSVG from './arrow.svg';
import {
  display,
  flexbox,
  layout,
  space,
  background,
  typography,
} from 'styled-utils';
import { Button } from 'components/Common/Button';
import { LadderThemeProp } from 'theme';

export const Wrapper = styled.div`
  ${background};
  ${space({ py: [18, 25] })};
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  ${space({ px: [23, 0] })};
`;

export const Title = styled.h2`
  ${typography({ textAlign: ['left', 'center'] })};
`;

export const Subtitle = styled.div`
  ${typography({ textAlign: ['left', 'center'] })};
  ${space({ mt: 12, mb: [26, 36] })};
`;

export const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  ${flexbox({ flexDirection: ['column-reverse', 'row'] })};
  ${layout({ height: ['auto', 380] })};
`;

export const Left = styled.div`
  flex-basis: 306px;
  ${flexbox({ flexBasis: ['auto', 306] })};
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${space({ paddingX: [23, 0] })};
`;

export const LeftText = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
`;

export const LeftTextMain = styled.div`
  ${layout({ minHeight: [130, 160] })};
`;

export const LeftTextName = styled.div`
  margin-top: 10px;
  ${smallStyle};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const LeftTextSubName = styled.div`
  ${xSmallStyle};
  color: ${({ theme }) => theme.colors.basic700};
`;

export const CTA = styled(Button)`
  ${space({ mt: [20, 30] })};
`;

export const Right = styled.div`
  ${display({ display: ['block', 'flex'] })};
  flex-basis: 1034px;

  ${layout({ height: [304, 'auto'] })};
  ${flexbox({ flex: [0, 1], flexBasis: [0, 1034] })};

  margin: 0 20px;

  .keen-slider {
    margin: 0 20px;

    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;

    &__slide {
      overflow: hidden;
      align-items: center;
      height: 100%;
    }

    &[data-keen-slider-v] {
      flex-wrap: wrap;
    }

    &[data-keen-slider-moves] * {
      pointer-events: none;
    }
  }
`;

export const ArrowHolder = styled.div<{ right?: boolean }>`
  padding-left: 20px;
  padding-right: ${({ right }) => (right ? '0px' : '20px')};
  height: 100%;
  align-items: center;
  justify-content: center;
  ${display({ display: ['none', 'flex'] })};
`;

export const Arrow = styled.button<{ right?: boolean }>`
  border: none;
  background-image: url('${ArrowSVG.url}');
  width: 28px;
  height: 55px;
  background-color: transparent;
  cursor: pointer;
  ${({ right }) => right && 'transform: rotate(180deg);'};
`;

export const SlideWrapper = styled.div<{ active: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
  ${({ active }) => space({ pl: [0, active ? 0 : '10%'] })};
`;

export const Image = styled(GatsbyImage)<{ active: boolean }>`
  transition: all 300ms;
  width: 100%;
  ${({ active }) => layout({ height: ['100%', active ? '100%' : '75%'] })};
`;

export const ImageText = styled.div`
  ${layout({ display: ['none', 'block'] })};
  ${smallStyle};
  color: ${({ theme }) => theme.colors.basic600};
  width: 100%;
  margin-top: 8px;
`;

export const Dots = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  ${space({ marginY: [20, 30] })};
`;

export const Dot = styled.button<{ active: boolean } & LadderThemeProp>`
  width: 35px;
  height: 5px;
  border: none;
  cursor: pointer;
  background: ${({ active, theme }) =>
    active ? theme.colors.blue700 : theme.colors.blue300};
  margin: 0 5px;
`;
