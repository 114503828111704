import React, { FC } from 'react';
import KeenSlider, { useKeenSlider } from 'keen-slider/react';
import { useIsMobile, useIsMobileOrTablet } from 'hooks/use-media-query';
import { PDPPageContainer } from 'components/Pdp';
import { SanityRichText } from 'components/Common/SanityRichText';
import {
  LadderHomeSliderProps,
  LadderHomeSlide,
} from './LadderHomeSlider.model';

import {
  Wrapper,
  Top,
  Title,
  Subtitle,
  SliderWrapper,
  Left,
  LeftText,
  LeftTextMain,
  LeftTextName,
  LeftTextSubName,
  CTA,
  Right,
  SlideWrapper,
  Image,
  ImageText,
  Dots,
  Dot,
} from './LadderHomeSlider.styles';
import { SliderArrows } from '../Slider/SliderArrows';
import { getUrl } from 'helpers/LinkObject';

const Slide: FC<
  LadderHomeSlide & { active: boolean; slider: KeenSlider; index: number }
> = ({ active, slider, index, image, name }) => (
  <SlideWrapper
    active={active}
    onClick={() => slider.moveToSlideRelative(index, true)}
  >
    {image?.image && (
      <Image
        image={image.image}
        alt={image.alt || ''}
        active={active}
        imgStyle={{ objectFit: 'cover' }}
      />
    )}
    {!active && <ImageText>{name}</ImageText>}
  </SlideWrapper>
);

export const LadderHomeSlider: React.FC<LadderHomeSliderProps> = (props) => {
  const { title, subtitle, slides, bgColor, cta } = props;

  const isMobileOrTablet = useIsMobileOrTablet();
  const isMobile = useIsMobile();

  const [currentSlide, setCurrentSlide] = React.useState(0);
  // Doc: https://keen-slider.io/api/#api
  const [ref, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slidesPerView: isMobile ? 1.5 : isMobileOrTablet ? 2 : 3,
    spacing: isMobile ? 15 : 0,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  const alternateRender = slides.length === 3;

  const dots = (
    <Dots>
      {slides.map((slide, idx) => (
        <Dot
          key={idx}
          active={
            (alternateRender && idx + 3 === currentSlide) ||
            idx === currentSlide
          }
          onClick={() =>
            slider.moveToSlideRelative(
              (alternateRender && currentSlide >= 3 && idx + 3) || idx,
              true
            )
          }
        />
      ))}
    </Dots>
  );

  const renderSlide = (slide: LadderHomeSlide, idx: number) => (
    <div key={idx} className="keen-slider__slide">
      <Slide
        {...slide}
        active={idx === currentSlide}
        slider={slider}
        index={idx}
      />
    </div>
  );

  const renderLeftText = (slide: LadderHomeSlide, idx: number) => (
    <LeftText
      key={idx}
      active={
        (alternateRender && idx + 3 === currentSlide) || idx === currentSlide
      }
    >
      <LeftTextMain>
        <SanityRichText blocks={slide.text} />
      </LeftTextMain>
      <LeftTextName>{slide.name}</LeftTextName>
      <LeftTextSubName>{slide.subname}</LeftTextSubName>
    </LeftText>
  );
  return (
    <Wrapper background={bgColor}>
      <PDPPageContainer>
        <Top>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Top>
        <SliderWrapper>
          <Left>
            {slides.map(renderLeftText)}
            {cta && (
              <CTA variant="primary" href={getUrl(cta.linkObject, cta.ctaLink)}>
                {cta.text}
              </CTA>
            )}
          </Left>

          {isMobile && dots}

          <Right>
            <SliderArrows slider={slider}>
              <div
                className="keen-slider"
                ref={ref}
                style={{
                  visibility: slider ? 'visible' : 'hidden',
                }}
              >
                {slides.map(renderSlide)}
                {alternateRender &&
                  slides.map((slide, idx) => renderSlide(slide, idx + 3))}
              </div>
            </SliderArrows>
          </Right>
        </SliderWrapper>

        {!isMobile && dots}
      </PDPPageContainer>
    </Wrapper>
  );
};
