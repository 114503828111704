import styled, { css } from 'styled-components';
import { display, DisplayProps, position } from 'styled-utils';

const getHeaderSize = (): { mobileHeight: string; desktopHeight: string } => {
  if (process.env.GATSBY_SITE === 'ladder') {
    return { mobileHeight: '50px', desktopHeight: '50px' };
  }
  return {
    mobileHeight: '60px',
    desktopHeight: '70px',
  };
};

function getStickyPosition(
  stickyPosition: 'bottom' | 'top' | 'topDesktop' = 'bottom'
) {
  const { mobileHeight, desktopHeight } = getHeaderSize();

  if (stickyPosition === 'top') {
    return css`
      top: ${mobileHeight};
      @media (min-width: 980px) {
        top: ${desktopHeight};
      }
    `;
  } else if (stickyPosition === 'topDesktop') {
    return css`
      ${position({
        top: ['auto', mobileHeight],
        bottom: ['0', 'auto'],
      })};
      @media (min-width: 980px) {
        top: ${desktopHeight};
      }
    `;
  }

  return 'bottom: 0;';
}

export const StickyElementStyle = styled.div<
  {
    position?: 'bottom' | 'top' | 'topDesktop';
    backgroundColor?: string;
  } & DisplayProps
>`
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`};
  ${display};
  ${({ position }) => getStickyPosition(position)};
  right: 0;
  left: 0;
  position: fixed;
  z-index: 10;
`;
